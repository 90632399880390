
import { defineComponent, ref, onMounted } from "vue";
import { useI18n } from "vue-i18n/index";
import axios, { AxiosResponse } from "axios";
import * as Yup from "yup";
import { ErrorMessage, Field, Form } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ApiRoutes from "@/core/config/ApiRoutes";
import { hideModal } from "@/core/helpers/dom";
import { checkField, resetField, enableSubmitButton, disableSubmitButton } from "@/core/helpers/form";
import FieldError from "@/components/modals/shared/FieldError.vue";

export default defineComponent({
    name: "referencials-products-edit-modal",
    emit: ["refreshData"],
    props: {
        product: null
    },
    components: {
        ErrorMessage,
        Field,
        Form,
        FieldError,
    },
    setup(props, { emit }) {
        const { t, te } = useI18n();
        const translate = (text, args = null) => {
            if (te(text)) {
                return t(text, args);
            } else {
                return text;
            }
        };

        let isUpdate = false as boolean;

        let modal = ref(null) as any;
        let brands = ref([]);
        let errors: any = ref();
        let attributes: any = ref([]);
        let productCategoriesL1 = ref<Array<any>>([]);
        let productCategoriesL2 = ref<Array<any>>([]);
        let productCategoriesL3 = ref<Array<any>>([]);
        let productCategoriesL4 = ref<Array<any>>([]);

        const submitButtonRef = ref<null | HTMLButtonElement>(null);
        const editEmployeeModalRef = ref<null | HTMLElement>(null);
        const productDefault = {
            id               : null,
            name             : "",
            barcode          : "",
            brand            : "",
            code             : "",
            categoryL1       : null,
            categoryL2       : null,
            categoryL3       : null,
            categoryL4       : null,
            low_stock_level  : 2,
            perishable       : 0,
            has_variants     : 0,
            attribute_id     : null,
            attributes_array : [],
            description      : "",
        };
        const productData = ref<any>({});
        Object.assign(productData.value, productDefault);

        const validationSchema = Yup.object().shape({
            // name: Yup.string().required().label(translate("productNameLabel")),
            // barcode: Yup.string().required().label(translate("productBarcodeLabel")),
            // brand: Yup.string().required().label(translate("productBrandLabel")),
            // code: Yup.string().nullable().label(translate("productCodeLabel")),
            // categoryL1: Yup.number().min(1).label(translate("productCategoryL1Label")),
            // categoryL2: Yup.number().nullable().label(translate("productCategoryL2Label")),
            // categoryL3: Yup.number().nullable().label(translate("productCategoryL3Label")),
            // categoryL4: Yup.number().nullable().label(translate("productCategoryL4Label")),
            // low_stock_level: Yup.number().min(1).label(translate("productLowStockLevelLabel")),
            // perishable: Yup.boolean().label(translate("productPerishableLabel")),
            // has_variants: Yup.boolean().label(translate("productHasVariationsLabel")),
            // description: Yup.string().nullable().label(translate("productDescriptionLabel")),
        });

        const submit = (values, actions) => {
            errors.value = null;

            disableSubmitButton(submitButtonRef);

            addAttribute();

            if (isUpdate && props.product.id) productData.value.id = props.product.id;

            axios.post(ApiRoutes.products.updateOrCreate, productData.value)
                .then((response: AxiosResponse) => {

                    enableSubmitButton(submitButtonRef);

                    if (response.data && response.data.success) {

                        Swal.fire({
                            text: response.data.message,
                            icon: "success",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        }).then(() => {
                            // actions.resetForm();

                            hideModal(editEmployeeModalRef.value);

                            emit("refreshData");
                        });

                    } else if (response.data && response.data.error) {

                        let error = (Array.isArray(response.data.error)) ? response.data.error.join('</li><li>') : response.data.error;

                        Swal.fire({
                            html: '<h3>' + translate("errorHeading") + '</h3><div class="alert alert-danger"><ul class="list-unstyled mb-0"><li>' + error + "</li></ul></div>",
                            icon: "error",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        });
                    }
                })
                .catch((error) => {

                    enableSubmitButton(submitButtonRef);

                    if (error.response.status == 422) {
                        errors.value = error.response.data.errors;
                    } else {
                        Swal.fire({
                            html: '<h3>' + translate("errorHeading") + '</h3><p>' + translate("errorConnectionMessage") + '</p>',
                            icon: "error",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        });
                    }
                });
        };

        const getCategories = async (level: number = 1, defaultValue = null) => {

            let params = { level : level, parent_id : null };

            switch (level) {
                case 2:
                    params.parent_id = productData.value.categoryL1;
                    break;

                case 3:
                    params.parent_id = productData.value.categoryL2;
                    break;

                case 4:
                    params.parent_id = productData.value.categoryL3;
                    break;
            }

            const response = await axios.get(ApiRoutes.productCategories.list, { params: params });

            switch (level) {

                case 2:

                    if (productData.value.categoryL1 == '') {
                        productData.value.categoryL1 = null;
                        productCategoriesL2.value = [];
                    } else {
                        productCategoriesL2.value = response.data;
                    }

                    productData.value.categoryL2 = (defaultValue) ? defaultValue : productDefault.categoryL2;

                    productData.value.categoryL3 = productDefault.categoryL3;
                    productCategoriesL3.value = [];
                    productData.value.categoryL4 = productDefault.categoryL4;
                    productCategoriesL4.value = [];
                    break;

                case 3:

                    if (productData.value.categoryL2 == '') {
                        productData.value.categoryL2 = null;
                        productCategoriesL3.value = [];
                    } else {
                        productCategoriesL3.value = response.data;
                    }

                    productData.value.categoryL3 = (defaultValue) ? defaultValue : productDefault.categoryL3;

                    productData.value.categoryL4 = productDefault.categoryL4;
                    productCategoriesL4.value = [];
                    break;

                case 4:

                    if (productData.value.categoryL3 == '') {
                        productData.value.categoryL3 = null;
                        productCategoriesL4.value = [];
                    } else {
                        productCategoriesL4.value = response.data;
                    }

                    productData.value.categoryL4 = (defaultValue) ? defaultValue : productDefault.categoryL4;

                    break;

                default:
                    productData.value.categoryL1 = (defaultValue) ? defaultValue : productDefault.categoryL1;
                    productCategoriesL1.value = response.data;

                    productData.value.categoryL2 = productDefault.categoryL2;
                    productCategoriesL2.value = [];
                    productData.value.categoryL3 = productDefault.categoryL3;
                    productCategoriesL3.value = [];
                    productData.value.categoryL4 = productDefault.categoryL4;
                    productCategoriesL4.value = [];
                    break;
            }
        };

        const getBrands = async () => {
            let response = await axios.get(ApiRoutes.products.brands);

            brands.value = response.data;
        };

        const getAttributes = async () => {
            const response = await axios.get(ApiRoutes.productAttributes.list);

            attributes.value = response.data;
        };

        const removeAttribute = (index: number, attribute_id: number) => {
            const i = attributes.value.findIndex(a => a.id == attribute_id);

            if (i >= 0) attributes.value[i].disabled = false;

            return productData.value.attributes_array.splice(index, 1);
        };

        const addAttribute = (event = null) => {

            if (productData.value.attribute_id != null) {
                const index = attributes.value.findIndex(a => a.id == productData.value.attribute_id);

                if (index >= 0) {
                    attributes.value[index].disabled = true;

                    productData.value.attributes_array.push({
                        id: productData.value.attribute_id,
                        name: attributes.value[index].name,
                        text: attributes.value[index].text,
                        options: attributes.value[index].options_array,
                        options_array: attributes.value[index].options_array,
                    });

                    productData.value.attribute_id = null;
                }
            }
        };

        const checkAttributes = async () => {

            if (productData.value.has_variants) {

                const response = await axios.get(ApiRoutes.productAttributes.count);

                if (response.data == 0) {

                    Swal.fire({
                        html: '<h3>' + translate("errorHeading") + '</h3><p>' + translate("productCheckAttribute") + '</p>',
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: translate("confirm"),
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                    });

                    productData.value.has_variants = false;
                }
            }
        }

        onMounted(async () => {
            getBrands();
            getAttributes();

            modal.value = document.getElementById('kt_modal_edit_product');

            if (modal.value) modal.value.addEventListener('hidden.bs.modal', function(event) {
                productCategoriesL2.value.length = 0;
                productCategoriesL3.value.length = 0;
                productCategoriesL4.value.length = 0;
                productData.value.attributes_array.length = 0;
                productData.value.attribute_options.length = 0;

                Object.assign(productData.value, productDefault);
            });

            if (modal.value) modal.value.addEventListener('shown.bs.modal', async function() {
                isUpdate = false;
                errors.value = null;

                if (props.product) {
                    isUpdate = true;

                    disableSubmitButton(submitButtonRef);

                    // Fill Intra fields
                    Object.keys(props.product).forEach((key) => {
                        if (typeof productDefault[key] == 'boolean') productData.value[key] = Boolean(props.product[key]);
                        else productData.value[key] = props.product[key];
                    });

                    await getCategories(1, props.product.categoryL1);

                    if (props.product.categoryL1) {
                        await getCategories(2, props.product.categoryL2);
                    }

                    if (props.product.categoryL2) {
                        await getCategories(3, props.product.categoryL3);
                    }

                    if (props.product.categoryL3) {
                        await getCategories(4, props.product.categoryL4);
                    }

                    if (props.product.attributes_array) {

                        props.product.attributes_array.forEach((attribute) => {
                            const index = attributes.value.findIndex(a => a.id == attribute.id);

                            if (index >= 0) attributes.value[index].disabled = true;
                        });
                    }

                    enableSubmitButton(submitButtonRef);

                } else {
                    getCategories();
                }
            });
        });

        return {
            translate,

            submitButtonRef,
            editEmployeeModalRef,

            validationSchema,

            errors,
            resetField,
            checkField,

            brands,
            modal,
            removeAttribute,
            checkAttributes,
            attributes,
            ApiRoutes,
            productCategoriesL1,
            productCategoriesL2,
            productCategoriesL3,
            productCategoriesL4,
            addAttribute,
            getCategories,
            productData,

            submit,
        };
    },
});
